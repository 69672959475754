//
//
//
//
//
//
//
//
//
//
//
//

import { faSparkle } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'SearchBarDropdownButton',
  components: {
    ProductIcon: () => import('@motionelements/core/src/components/product/ProductIcon.vue'),
  },
  props: {
    chnnelTyoe: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      faSparkle,
    };
  },
  computed: {
    isNew() {
      return ['graphic', 'ui', 'presentation', 'notion', 'midjourney_prompt', 'image_prompt'].includes(this.chnnelTyoe);
    },
  },
};
